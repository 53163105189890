import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import Seo from "../components/seo"

function ThankYou() {
    return (
        <Layout>
            <Seo
                title="Thank you"
                description="Your message has been received."
            />

            <Page pageTitle="Thank you" pageClass="page-thanks">
                <p>Thank you for getting in touch with us. Your message has been received and we will respond shortly.</p>
                <br />
                <p>If you haven't already done so, we invite you to connect with us through our social networks:</p>
                <ul>
                    <li><a href="https://twitter.com/annaabramowski" target="_blank" rel="noopener noreferrer">Twitter</a></li>
                    <li><a href="https://www.linkedin.com/in/abramowski/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
                    <li><a href="https://m.facebook.com/DrAnnaAbramowski/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
                </ul>
            </Page>

            <section className="section section-form section-form-thanks">
                <div className="container">
                    <div className="form-box form-box-thanks">
                        <img src={`../../assets/images/hcpc-reg_orig.png`} alt="Health and Care Professions Council" width="80" height="68" />
                        <img src={`../../assets/images/bps.png`} alt="British Psychological Society" width="135" height="68" />
                    </div>
                </div>
            </section>

        </Layout>
    );
}

export default ThankYou
